/* src/PublicHomePage.css */
.PublicHomePage {
    text-align: center;
  }
  
  .hero {
    margin-bottom: 2rem;
  }
  .imageContainer {
    position: relative;
  }
  
  .buttonContainer {
    position: absolute;
    top: 75%;
    left: 35%;
    transform: translate(-50%, -50%);
    display: grid;
    place-items: center;
  }
  
  .features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .feature {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform .3s ease-in-out;
  }
  
  .feature:hover {
    transform: scale(1.05);
  }
  
  .feature-icon {
    font-size: 2em;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .feature-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
  }
  
  .feature-button:hover {
    background-color: #0056b3;
  }
 
  
  .contact {
    margin-bottom: 2rem;
  }
  .centerImage{
    width:70%;
  }